
/**
 * 养犬详情
 */
export class BreedDetailsModel {
    /**
     * 犬主ID
     */
    public ownerId?: string;
    /**
     * 犬主姓名
     */
    public ownerName?: string;
    /**
     * 性别
     */
    public ownerGender?: string;
    /**
     * 民族
     */
    public nationalId?: string;
    /**
     * 电话号码
     */
    public telephone?: string;
    /**
     * 证件类型编码
     */
    public certificateType?: string;
    /**
     * 证件号码
     */
    public certificateNo?: string;
    /**
     * 残疾等级
     */
    public disabledType?: number;
    /**
     * 残疾证号
     */
    public disabledCertificateNo?: string;
    /**
     * 残疾证明
     */
    public disabledCertificateImg?: string;
    /**
     * 证件正面照
     */
    public certificateFrontImg?: string;
    /**
     * 证件背面照
     */
    public certificateBackImg?: string;

    // 单位犬主
    /**
     * 统一社会信用代码
     */
    public unifiedCode?: string;
    /**
     * 法人姓名
     */
    public legalName?: string;
    /**
     * 养犬人姓名
     */
    public breedName?: string;
    /**
     * 营业执照照片
     */
    public licenseImg?: string;
    /**
     * 犬主类型
     */
    public ownerType?: number;
    /**
     * 行版本
     */
    public ownerRowVersion?: Date;

    /**
     * 犬ID
     */
    public petId?: string;
    /**
     * 犬名
     */
    public petName?: string;
    /**
     * 犬性别
     */
    public petGender?: string;
    /**
     * 犬登记编码
     */
    public registrationNo?: string;
    /**
     * 电子标识码
     */
    public tagNo?: string;
    /**
     * 电子犬证
     */
    public petCertificateImg?: any;
    /**
     * 犬种ID
     */
    public petKindId?: string;
    /**
     * 犬类犬种
     */
    public petKindIds?: Array<string>;
    /**
     * 毛色
     */
    public hairColor?: string;
    /**
     * 绝育证编号
     */
    public neuteredNo?: string;
    /**
     * 养犬地址
     */
    public address?: string;
    /**
     * 免疫编号
     */
    public immunityNo?: string;
    /**
     * 免疫日期
     */
    public immunityDate?: Date;
    /**
     * 历史限养区类型
     */
    public oldAreaType?: number;
    /**
     * 限养区类型
     */
    public areaType?: number;
    /**
     * 犬主合照
     */
    public petAndOwnerImg?: string;
    /**
     * 正面照
     */
    public petFrontImg?: string;
    /**
     * 侧面照
     */
    public petSideImg?: string;
    /**
     * 年检日期
     */
    public annualInspectionDate?: Date;
    /**
     * 下次年检日期
     */
    public needAnnualInspectionDate?: Date;
    /**
     * 管辖区域ID
     */
    public areaCode?: string;
    /**
     * 级联辖区
     */
    public areaCodes?: Array<string>;
    /**
     * 组织机构
     */
    public organizationCode?: string;
    /**
     * 审核日期
     */
    public auditDate?: Date;
    /**
     * 制卡标记
     */
    public cardFlag?: string;
    /**
     * 犬描述
     */
    public petRemark?: string;
    /**
     * 鼻纹采集
     */
    public noseprintImg?: string;
    /**
     * 登记时间
     */
    created?: Date;
    /**
     * 创建人ID
     */
    createdBy?: string;
    /**
     * 创建人姓名
     */
    createdByName?: string;
    /**
     * 最后修改人ID
     */
    lastModifiedBy?: string;
    /**
     * 最后修改人姓名
     */
    lastModifiedByName?: string;
    /**
     * 犬行版本
     */
    petRowVersion?: Date;
    /**
     * 设备编码
     */
    imei?: string;

    //#region 预约信息
    /**
     * OpenID
     */
    openid?: string;
    /**
     * UnionID
     */
    unionid?: string;
    /**
     * 预约号
     */
    applyNo?: string;
    /**
     * 预约手机号
     */
    applyPhone?: string;

    //#endregion

    constructor(init?: Partial<BreedDetailsModel>) {
        Object.assign(this, init);
    }
}
