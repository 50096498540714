import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class PhotoHelper {
    private DEFAULT_HEIGTH = 160;
    // 文件api
    private fileApi = `${environment.SERVER.URL}/api/file`;
    private imgType = 'image/png';
    private canvas: HTMLCanvasElement = document.createElement('canvas');

    // tslint:disable-next-line:variable-name
    private readonly _navigator: any = navigator;
    private localStream: any;

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe
    ) {
        // Older browsers might not implement mediaDevices at all, so we set an empty object first
        if (this._navigator.mediaDevices === undefined) {
            this._navigator.mediaDevices = {};
        }

        // Some browsers partially implement mediaDevices. We can't just assign an object
        // with getUserMedia as it would overwrite existing properties.
        // Here, we will just add the getUserMedia property if it's missing.
        if (this._navigator.mediaDevices.getUserMedia === undefined) {
            this._navigator.mediaDevices.getUserMedia = (constraints): Promise<any> => {
                // 兼容处理
                // First get ahold of the legacy getUserMedia, if present
                // tslint:disable-next-line:max-line-length
                const getUserMedia = this._navigator.webkitGetUserMedia || this._navigator.mozGetUserMedia || this._navigator.msGetUserMedia;

                // Some browsers just don't implement it - return a rejected promise with an error
                // to keep a consistent interface
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                return new Promise((resolve, reject) => {
                    getUserMedia.call(this._navigator, constraints, resolve, reject);
                });
            };
        }
    }

    /**
     * 打开摄像头
     * @param video video标签
     */
    public getMedia(video: HTMLVideoElement): void {
        try {
            const width = video.width ? video.width : this.DEFAULT_HEIGTH;
            const height = video.height ? video.height : this.DEFAULT_HEIGTH;

            const constraints = {
                // 视频
                video: {
                    width: this.DEFAULT_HEIGTH * (width / height),
                    height: this.DEFAULT_HEIGTH
                },
                // 音频
                audio: false
            };

            // 这里介绍新的方法，返回一个 Promise对象
            // 这个Promise对象返回成功后的回调函数带一个 MediaStream 对象作为其参数
            // then()是Promise对象里的方法
            // then()方法是异步执行，当then()前的方法执行完后再执行then()内部的程序
            // 避免数据没有获取到
            this._navigator.mediaDevices.getUserMedia(constraints)
                .then((stream) => {
                    this.localStream = stream;

                    const videoElemnet = document.createElement('video');

                    // Older browsers may not have srcObject
                    if ('srcObject' in videoElemnet) {
                        video.srcObject = stream;
                    } else {
                        // Avoid using this in new browsers, as it is going away.
                        (video as any).src = window.URL.createObjectURL(stream);
                    }

                    video.onloadedmetadata = (e) => {
                        video.play();
                    };
                })
                .catch((err: Error) => {
                    console.log(err.name + ':' + err.message);
                });
        } catch (err) {
            // console.log(err, 'getMedia===拍照异常');

            throw err;
        }
    }

    /**
     * 获取照片
     * @param video video标签
     * @param canvas canvas标签
     */
    public takePhoto(video: HTMLVideoElement): string {
        let result: string = null;
        if (!video) {
            throw new Error('video标签未获取到');
        }

        try {
            const zoom = 1;
            const width = video.width ? video.width : 200;
            const height = video.height ? video.height : 200;

            this.canvas.width = this.DEFAULT_HEIGTH * (width / height) * zoom;
            this.canvas.height = this.DEFAULT_HEIGTH * zoom;
            const ctx = this.canvas.getContext('2d');

            ctx.drawImage(video, 0, 0, this.canvas.width, this.canvas.height);
            // 返回base64图片
            result = this.canvas.toDataURL(this.imgType);

            return result;
        } catch (ex) {
            console.log(ex, 'takePhoto===拍照异常');

            throw ex;
        }
    }

    /**
     * Url转Base64
     * @param url 图片路径
     * @param callBack 回调函数
     */
    imgToBase64(url: string, callBack: (params: any) => void): void {
        const image = new Image();
        image.crossOrigin = '';
        image.src = url;

        const that = this;
        image.onload = () => {
            const base64 = that.getBase64Image(image);
            if (callBack) {
                callBack(base64);
            }
        };
    }

    /**
     * Image转换为Base64
     * @param img 图片
     */
    getBase64Image(img: HTMLImageElement): string {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
        const dataURL = canvas.toDataURL('image/' + ext);
        return dataURL;
    }

    /**
     * base64转文件
     * @param dataUrl base64数据
     */
    dataURLtoBlob(dataUrl: string): Blob {
        let result: Blob = null;

        if (!dataUrl) {
            return result;
        }

        try {
            const arr = dataUrl.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const suffix = mime.split('/')[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            // 转换成成blob对象
            result = new Blob([u8arr], { type: mime });

            return result;
        } catch (ex) {
            console.log(ex, 'dataURLtoBlob===拍照异常');

            throw ex;
        }
    }

    /**
     * 将blob转换为file
     * @param theBlob blob
     * @param fileName 文件名
     */
    blobToFile(theBlob: any, fileName: string): any {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;

        return theBlob;
    }

    /**
     * 上传文件
     * @param file 文件
     */
    uploadFile(file: File): Observable<any> {
        if (!file) {
            return of(null);
        }

        try {
            const data: FormData = new FormData();
            data.append('file', file, file.name);
            const header = new HttpHeaders();
            header.append('Accept', 'application/json');
            const options = { headers: header };

            return this.http.post(this.fileApi, data, options);
        } catch (ex) {
            console.log(ex, 'uploadFile===拍照异常');
            return of(null);
        }
    }

    /**
     * 拍照并上传
     * @param video video标签
     */
    takeAndUpload(video: HTMLVideoElement): Observable<any> {
        try {
            // 获取数据源
            const base64Str: string = this.takePhoto(video);

            // 获取Blob
            const blob: Blob = this.dataURLtoBlob(base64Str);

            // 转文件
            const file: File = this.blobToFile(
                blob,
                `${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}.${this.imgType.split('/')[1]}`
            );

            // 上传文件
            return this.uploadFile(file);
        } catch (ex) {
            console.log(ex, 'takeAndUpload===拍照异常');
            throw ex;
        }
    }

    /**
     * 关闭视频流
     */
    stopStream(): void {
        if (this.localStream) {
            const tracks = this.localStream.getTracks();
            tracks.forEach((track: any) => {
                track.stop();
            });
        }
    }
}
