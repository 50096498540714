<div nz-row nzType="flex" nzAlign="top" nzJustify="space-around">
    <!-- 基本信息 -->
    <div nz-col nzSm="24" nzMd="24" nzLg="24" nzXl="12">
        <div nz-row>
            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">犬名：</p>
                    <span>{{model?.petName}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">犬性别：</p>
                    <span>{{model?.petGender | dictName:'DogGender' | async}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">犬类：</p>
                    <span>{{model?.petKindId | petKindName | async}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">毛色：</p>
                    <span>{{model?.hairColor}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">免疫编号：</p>
                    <span>{{model?.immunityNo}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">免疫日期：</p>
                    <span>{{model?.immunityDate | date:'yyyy-MM-dd'}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">限养区类型：</p>
                    <ng-container
                        *ngIf="model && model?.oldAreaType > 0 && model?.oldAreaType !== model?.areaType; else elseAreaTypeTemplate">
                        <span style="color: red;">
                            <span>{{model?.oldAreaType | dictName:'AreaType' | async}}</span>
                            <i nz-icon nzType="arrow-right" nzTheme="outline" style="margin: 0 8px;"></i>
                            <span>{{model?.areaType | dictName:'AreaType' | async}}</span>
                        </span>
                    </ng-container>
                    <ng-template #elseAreaTypeTemplate>
                        <span>{{model?.areaType | dictName:'AreaType' | async}}</span>
                    </ng-template>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">管辖区域：</p>
                    <span>{{ model?.areaCode | areaName | async }}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">绝育证编号：</p>
                    <span>{{model?.neuteredNo}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12" nzLg="8" nzXl="8">
                <div class="item-wrap">
                    <p class="label-90">应年检日期：</p>
                    <span>{{model?.annualInspectionDate | date:'yyyy-MM-dd'}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12">
                <div class="item-wrap">
                    <p class="label-90">养犬地址：</p>
                    <span>{{model?.address}}</span>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="12">
                <div class="item-wrap">
                    <p class="label-90">犬描述信息：</p>
                    <span>{{model?.petRemark}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- 犬照片 -->
    <div nz-col nzSm="24" nzMd="24" nzLg="24" nzXl="8">
        <div nz-row>
            <div nz-col nzSm="24" nzMd="8" nzLg="8" nzXl="12" style="min-height: 65px;">
                <div class="item-wrap">
                    <p class="label-90">犬主合照：</p>
                    <img *ngIf="(model && model.petAndOwnerImg)" [src]="model.petAndOwnerImg | imgSrc"
                        (click)="showImage(model.petAndOwnerImg)" style="cursor: pointer; width: 88px; height: 60px;" />
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="8" nzLg="8" nzXl="12" style="min-height: 65px;">
                <div class="item-wrap">
                    <p class="label-90">正面照：</p>
                    <img *ngIf="(model && model.petFrontImg)" [src]="model.petFrontImg | imgSrc"
                        (click)="showImage(model.petFrontImg)" style="cursor: pointer; width: 88px; height: 60px;" />

                </div>
            </div>

            <div nz-col nzSm="24" nzMd="8" nzLg="8" nzXl="12" style="min-height: 65px;">
                <div nz-row>
                    <div class="item-wrap">
                        <p class="label-90">侧面照：</p>
                        <img *ngIf="(model && model.petSideImg)" [src]="model.petSideImg | imgSrc"
                            (click)="showImage(model.petSideImg)" style="cursor: pointer; width: 88px; height: 60px;" />
                    </div>
                </div>
            </div>

            <div nz-col nzSm="24" nzMd="8" nzLg="8" nzXl="12" style="min-height: 65px;">
                <div nz-row>
                    <div class="item-wrap">
                        <p class="label-90">鼻纹采集：</p>
                        <img *ngIf="(model && model.noseprintImg)" [src]="model.noseprintImg | imgSrc"
                            (click)="showImage(model.noseprintImg)"
                            style="cursor: pointer; width: 88px; height: 60px;" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 占位 -->
    <div nz-col nzSm="24" nzMd="24" nzLg="24" nzXl="4">
        <div nz-row>
            <div nz-col style="min-height: 65px;">
                <div class="item-wrap">
                    <!-- <p class="label">电子犬证：</p>
                    <img [src]="" style="width: 88px; height: 60px; border: 0px;" /> -->
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #elseTemplate>
    <nz-avatar [nzShape]="'square'" [nzSize]="128" nzIcon="camera" style="background-color:#cecece;">
    </nz-avatar>
</ng-template>

<ng-template #modalContent>
    <img *ngIf="!!previewUrl" [src]="previewUrl" [ngStyle]="{ width: '100%' }" />
</ng-template>